import { graphql } from "gatsby";
import React from "react";
import MuseumDesktop from "../components/museum/Desktop";
import MuseumMobile from "../components/museum/Mobile";
import useDimensions from "../hooks/useDimensions";

const Museum = ({ data }) => {
  const [width] = useDimensions();
  const templateData = data.sanityPage;
  const museumData = templateData.museumTemp;

  return (
    <div
      style={{
        backgroundColor: "black",
      }}
    >
      {width &&
        (width > 768 ? (
          <MuseumDesktop data={museumData} />
        ) : (
          <MuseumMobile data={museumData} />
        ))}
    </div>
  );
};

export const query = graphql`
  query MyMuseumQuery($slug: String!) {
    sanityPage(slug: { current: { eq: $slug } }) {
      title
      template
      slug {
        current
      }
      museumTemp {
        back {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        logo {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        museum {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        museumList {
          image {
            asset {
              altText
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
          alt
        }
      }
    }
  }
`;

export default Museum;
