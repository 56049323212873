import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Menu from "../../common/Menu";
import Gallery from "./Gallery";
const MuseumDesktop = ({ data }) => {
  return (
    <div className="cemetery">
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-md-2">
            <Link to="/">
              <GatsbyImage
                image={data.back.asset.gatsbyImageData}
                alt={data.back.asset.altText || "Back to Home"}
                className="img-fluid museum-tatoo1"
                loading="eager"
                style={{ width: "75%" }}
              />
            </Link>
          </div>
          <div className="col-lg-8 col-md-8">
            <center>
              <Link to="/">
                <GatsbyImage
                  image={data.logo.asset.gatsbyImageData}
                  alt={data.logo.asset.altText || "Matthew Gray Gubler"}
                  className="img-fluid cemetery-tatoo3"
                  loading="eager"
                />
              </Link>
              <Link to="/museum">
                <GatsbyImage
                  image={data.museum.asset.gatsbyImageData}
                  alt={data.museum.asset.altText || "Museum"}
                  className="img-fluid cemetery-tatoo4"
                  loading="eager"
                  style={{ width: "37%" }}
                />
              </Link>
              <br />
            </center>
          </div>
          {/* Menu */}
          <div className="col-lg-2 col-md-2">
            <Menu />
          </div>
        </div>
        <div className="clearfix" />
        <br />
        <br />
        <Gallery images={data.museumList} />
      </div>
    </div>
  );
};

export default MuseumDesktop;
