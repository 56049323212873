import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import Menu from "../../common/Menu";

const MobileMenu = ({ data }) => {
  const [openMenu, setOpenMenu] = useState(false);
  function handleClick(e) {
    e.preventDefault();
    setOpenMenu((prevState) => !prevState);
  }
  return (
    <div className="row">
      <div className="col-sm-12 col-12">
        {/*Burger Menu Start-*/}
        <Menu />
        {/*Burger Menu End-*/}
        <Link to="/">
          <GatsbyImage
            image={data.back.asset.gatsbyImageData}
            alt={data.back.asset.altText || "Back to Home"}
            loading="eager"
            className="img-fluid cemetery-mobile-tatoo1"
          />
        </Link>
        <br />
        <center>
          <Link to="/">
            <GatsbyImage
              image={data.logo.asset.gatsbyImageData}
              alt={data.logo.asset.altText || "Matthew Gray Gubler"}
              loading="eager"
              className="img-fluid cemetery-mobile-tatoo2"
            />
          </Link>
        </center>
        <br />
        <br />
        <center>
          <Link to="/museum">
            <GatsbyImage
              image={data.museum.asset.gatsbyImageData}
              alt={data.museum.asset.altText || "Museum"}
              loading="eager"
              className="img-fluid cemetery-mobile-tatoo3"
              style={{ width: "50%" }}
            />
          </Link>
        </center>
        <br />
      </div>
    </div>
  );
};

export default MobileMenu;
