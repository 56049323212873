import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Lightbox from "./Lightbox";

const Gallery = ({ images }) => {
  const [lightbox, setLightbox] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [image, setImage] = useState(images[0]);

  function handleModal(id, img) {
    setCurrentIndex(id);
    setImage(img);
    setLightbox((prevState) => !prevState);
  }

  function handleNext() {
    if (currentIndex === images.length - 1) {
      setCurrentIndex(0);
      setImage(images[0]);
    } else {
      setCurrentIndex((prevState) => prevState + 1);
      setImage(images[currentIndex + 1]);
    }
  }

  function handlePrev() {
    if (currentIndex === 0) {
      setCurrentIndex(images.length - 1);
      setImage(images[images.length - 1]);
    } else {
      setCurrentIndex((prevState) => prevState - 1);
      setImage(images[currentIndex - 1]);
    }
  }

  function onClose() {
    setLightbox(false);
  }

  return (
    <div className="row gallery-grid-top">
      {images.map((img, index) => (
        <div className="col-md-3 col-sm-6 cemetry-grid" key={index}>
          <center>
            <div
              className="cemetry-grid-img"
              onClick={() => handleModal(index, img)}
              style={{ cursor: "pointer" }}
            >
              <GatsbyImage
                image={img.image.asset.gatsbyImageData}
                alt={img.alt || `Gallery Image`}
                className="img-fluid zoom-img"
                loading="lazy"
                layout="fullWidth"
              />
              <div className="agile-b-wrapper">
                <i className="fa fa-search-plus" aria-hidden="true" />
              </div>
            </div>
          </center>
        </div>
      ))}
      <div className="clearfix"> </div>
      {!!lightbox && (
        <Lightbox
          current={image}
          handleNext={handleNext}
          handlePrev={handlePrev}
          onClose={onClose}
        />
      )}
    </div>
  );
};

export default Gallery;
