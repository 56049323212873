import React from "react";
import MobileGallery from "./MobileGallery";
import MobileMenu from "./MobileMenu";

const MuseumMobile = ({ data }) => {
  return (
    <div className="cemetery-mobile">
      <div className="container-fluid">
        <MobileMenu data={data} />
        <div className="clearfix" />
        <MobileGallery images={data.museumList} />
      </div>
    </div>
  );
};

export default MuseumMobile;
