import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Lightbox from "../Desktop/Lightbox";

const MobileGallery = ({ images }) => {
  const [lightbox, setLightbox] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [image, setImage] = useState(images[0]);

  function handleModal(id, img) {
    setCurrentIndex(id);
    setImage(img);
    setLightbox((prevState) => !prevState);
  }

  function handleNext() {
    if (currentIndex === images.length - 1) {
      setCurrentIndex(0);
      setImage(images[0]);
    } else {
      setCurrentIndex((prevState) => prevState + 1);
      setImage(images[currentIndex + 1]);
    }
  }

  function handlePrev() {
    if (currentIndex === 0) {
      setCurrentIndex(images.length - 1);
      setImage(images[images.length - 1]);
    } else {
      setCurrentIndex((prevState) => prevState - 1);
      setImage(images[currentIndex - 1]);
    }
  }

  function onClose() {
    setLightbox(false);
  }

  return (
    <div className="row gallery-grid-top">
      {images.map((image, index) => (
        <div className="col-6 cemetry-grid">
          <center>
            <div
              className="cemetry-grid-img"
              onClick={() => handleModal(index, image)}
              style={{ cursor: "pointer" }}
            >
              <GatsbyImage
                image={image.image.asset.gatsbyImageData}
                alt={image.image.asset.altText || `Gallery Image`}
                className="img-fluid zoom-img"
                loading="lazy"
              />
              <div className="agile-b-wrapper">
                <i className="fa fa-search-plus" aria-hidden="true" />
              </div>
            </div>
          </center>
        </div>
      ))}

      <div className="clearfix"> </div>
      <div>
        {!!lightbox && (
          <Lightbox
            current={image}
            handleNext={handleNext}
            handlePrev={handlePrev}
            onClose={onClose}
          />
        )}
      </div>
    </div>
  );
};

export default MobileGallery;
